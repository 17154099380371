::placeholder {
	color: #fff !important;
	opacity: 1 !important; /* Firefox */
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #fff !important;
	opacity: 1;
}

::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #fff !important;
}

.signup-layout > * {
	color: white !important;
}

.ant-card {
	border: 1px solid grey;
}

.ant-card img {
	border: 1px solid grey;
}

.slick-slide {
	display: flex;
	align-items: center;
	justify-content: center;
}

.slick-arrow {
	display: flex !important;
	align-items: center;
	justify-content: center;
}

.slick-arrow > * {
	color: #9e9e9e;
	font-size: 2rem;
	font-weight: bold;
}

.slick-arrow {
	width: 25px !important;
	height: 25px !important;
}

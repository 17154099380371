.navbar {
	padding: 10px 20px;
}

.nav-item.dropdown {
	padding-left: 40px;
}

.nav_btn {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 50%;
}

.navbar-brand {
	margin: 0px !important;
}

.navbar-brand img {
	width: 100%;
	max-width: 70px;
}

.navbar a.nav-link {
	font-weight: 400;
	color: #000000;
	font-size: 16px;
}

.navbar-toggler {
	position: absolute;
	top: 10px;
	right: 10px;
	background-color: #fff !important;
}

.nav-bar-items {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.navbar-nav {
	margin-left: auto !important;
	margin-right: auto !important;
}

@media (max-width: 768px) {
	.nav-bar-items {
		display: flex !important;
		flex-direction: column !important;
		align-items: left !important;
		justify-content: left !important;
	}

	.nav-bar-items > * {
		margin-right: auto !important;
	}

	.navbar-nav {
		margin: 0 !important;
		display: flex !important;
		align-items: left !important;
		width: 100%;
	}

	.nav-link-div {
		margin: 0 !important;
		width: 100%;
		display: flex !important;
		align-items: left !important;
		padding-left: 5px;
	}

	.lg-view-only {
		display: none;
	}

	.navbar-brand img {
		width: 100%;
		max-width: 360px;
		margin-left: 0;
	}

	#basic-nav-dropdown {
		position: relative;
		top: 0;
	}
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
	margin: 0;
	cursor: default;
}

body.wait {
	cursor: wait;
}

* {
	font-family: "Poppins", sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
} */

.center-div {
	display: flex;
	justify-content: center;
	align-items: center;
}

.break_line {
	background: transparent;
	border: 1px solid #000;
	margin: 60px 0;
}

.gradient-purple-text {
	background: linear-gradient(
		to right,
		#e4b9d0 0%,
		#be7eb4 50.56%,
		#7b7bba 100%
	);
	font-size: 52px;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight: 700;
}

.ant-btn-primary {
	background: linear-gradient(
		to right,
		#e4b9d0 0%,
		#be7eb4 50.56%,
		#7b7bba 100%
	);
}

.primary-gradient-background {
	background: linear-gradient(
		to right,
		#e4b9d0 0%,
		#be7eb4 50.56%,
		#7b7bba 100%
	);
}

.ant-btn-primary:hover {
	background: linear-gradient(
		to right,
		#7b7bba 0%,
		#be7eb4 50.56%,
		#e4b9d0 100%
	);
	color: white;
}

.slick-list > .slick-track {
	display: flex;
	justify-content: center;
	align-items: center;
}

.pre-text {
	white-space: pre;
}

.left-div {
	display: flex;
	justify-content: left;
	align-items: center;
}

input:invalid,
textarea:invalid {
	animation: shake 0.2s ease-in-out 0s 2;
	/* box-shadow: 0 0 0.6rem #ff0000; */
	color: red !important;
}

.link-no-text-decoration {
	text-decoration: none !important;
	color: black !important;
}

button:focus {
	outline: none !important;
	outline-offset: none !important;
	box-shadow: none !important;
}

.p-600 {
	font-weight: 600;
}

.p-09 {
	font-size: 0.9rem;
}

.ant-select-selection-placeholder {
	color: red !important;
}

input::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: grey !important;
	opacity: 1; /* Firefox */
}
input::-webkit-input-placeholder {
	/* WebKit, Blink, Edge */
	color: grey !important;
}
input:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: grey !important;
	opacity: 1;
}
input::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: grey !important;
	opacity: 1;
}
input:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: grey !important;
}
input::-ms-input-placeholder {
	/* Microsoft Edge */
	color: grey !important;
}

.PhoneInputInput {
	border-radius: 6px;
	padding: 4px 11px;
	color: rgba(0, 0, 0, 0.88);
	/* box-shadow: 0px 0px 5px rgb(221, 164, 224); */
	border: 1px solid rgb(217, 217, 217);
}

.row-div {
	display: flex;
	flex-direction: row;
}
